import React from 'react';
import NavLinks from 'components/NavLinks';
import Link from 'gatsby-link';
import * as styles from './styles.module.scss';
import Logo from 'components/Logo/Long';

class Overlay extends React.Component {
  render() {
    const classList = [styles.root];
    this.props.open && classList.push(styles.open);

    return (
      <div className={classList.join(' ')}>
        {
          <div>
            <div className={styles.modalTitle}>
              <Link to="/" onClick={() => this.props.toggleOverlay()}>
                <Logo className={styles.svg} />
              </Link>
            </div>
            <NavLinks modal={true} onLinkClick={() => this.props.toggleOverlay()} />
          </div>
        }
      </div>
    );
  }
}

export default Overlay;
