import React from "react"
import Spinner from "components/Spinner"
import getEmail from "utils/get-email"
import * as styles from './styles.module.scss';

const STATUS_LOADING = 'loading'
const STATUS_SUCCESS = 'success'

class LoginModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { status: STATUS_LOADING, showWarning: false }
  }

  onIframeLoaded() {
    this.setState({ status: STATUS_SUCCESS })
  }

  componentDidMount() {
    document && document.addEventListener("keydown", this.onKeyDown);

    this.timerID = setInterval(
      () => this.setState({ showWarning: true }),
      15000
    );
  }

  componentWillUnmount() {
    document && document.removeEventListener("keydown", this.onKeyDown);

    clearInterval(this.timerID);
  }

  onKeyDown = (event) => {
    // Esc key:
    if(event.keyCode === 27) {
      this.props.onClose()
    }
  }

  render() {
    const status = this.state.status
    const email = getEmail()

    return (
      <div className={styles.root}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div className={styles.shadow} onClick={() => this.props.onClose()} role="button" aria-label="close" tabIndex={0}/>
        <div className={styles.content}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div className={styles.close} onClick={() => this.props.onClose()} role="button" aria-label="close" tabIndex={0}>&#10005;</div>
          <h3 className={styles.header}>Portal Login</h3>
          <p>Enrolled students and parents can login here for access to the Stonybrook Strings learning portal. </p>
          {status === STATUS_LOADING && <Spinner dark />}
          <div className={styles.form} style={{ display: status === STATUS_SUCCESS ? 'block' : 'none' }}>
            <iframe
              title="My Music Staff Login"
              src='//app.mymusicstaff.com/Widget/v2/Login.aspx?Sandboxed=false'
              allowtransparency='true'
              frameBorder={0}
              style={{ width: "100%", height: '100%' }}
              scrolling="no"
              onLoad={() => this.onIframeLoaded()}
            >  
            </iframe>
          </div>

          {this.state.showWarning && <div className={styles.warning}>
            <p className={styles.bold}>Having issues logging in?</p>
            <p>Try refreshing the page. If that doesn't fix it, please send us an email at{' '}
              <a className={styles.emailLink} href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
              {' '}and we will be in touch as soon as we can
            </p>
          </div>}
        </div>
      </div>
    );
  }
}
  
  export default LoginModal;