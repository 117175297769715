import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby'

import Header from 'components/Header';
import OverlayMenu from 'components/Overlay';
import Footer from 'components/Footer';

import 'assets/styles/global.scss';

export const query = graphql`
  query SiteMetadataQuery {
    site {
      siteMetadata {
        title, siteUrl, description
      }
    }
  }
`;

class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { overlayOpen: false };
  }

  isHeaderTransparent() {
    const pathname = this.props.location.pathname.replace(/\/$/, ''); // Replace trailing slash
    return pathname === '' || pathname === '/contact';
  }

  toggleOverlay() {
    this.setState({ overlayOpen: !this.state.overlayOpen });
  }

  render() {
    const { loginModalInitiallyOpen } = this.props
    return (
        <StaticQuery query={query} render={data => {
          const { siteUrl } = data.site.siteMetadata,
            title = this.props.title || data.site.siteMetadata.title,
            description = this.props.description || data.site.siteMetadata.description,
            shareImageUrl = `${siteUrl.replace(/\/$/, "")}/sbs-share.jpg`;

          return <div>
              <Helmet>
                <title>{title}</title>
                <meta property="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={siteUrl} />
                <meta property="og:image" content={shareImageUrl} />
                <meta property="og:description" content={description} />
                <meta name="description" content={description} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" content={description} />
              </Helmet>
              <div id="root">
                <Header
                    location={this.props.location}
                    transparent={this.isHeaderTransparent()}
                    overlayOpen={this.state.overlayOpen}
                    toggleOverlay={() => this.toggleOverlay()}
                    loginModalInitiallyOpen={loginModalInitiallyOpen }
                />
                {this.props.children}
                <Footer />
                <OverlayMenu open={this.state.overlayOpen} toggleOverlay={() => this.toggleOverlay} />
              </div>
            </div>
        }} />
    );
  }
}

export default TemplateWrapper;
