import React from 'react';
import * as styles from './styles.module.scss';

// Grabbed from Tobias Ahlin: http://tobiasahlin.com/spinkit/
class Spinner extends React.Component {
  render() {
    const isDark = !!this.props.dark
    return (
      <div className={`${styles.skCircle} ${isDark ? styles.dark : ''}`}>
        <div className={`${styles.skChild}`}></div>
        <div className={`${styles.skCircle2} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle3} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle4} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle5} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle6} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle7} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle8} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle9} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle10} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle11} ${styles.skChild}`}></div>
        <div className={`${styles.skCircle12} ${styles.skChild}`}></div>
      </div>
    );
  }
}

export default Spinner;
