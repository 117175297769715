import React from 'react';

// import FbLogo from 'components/icons/Facebook';
import InstagramLogo from 'components/icons/Instagram';
import * as styles from './styles.module.scss';

const Footer = () => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.left}>
        <div>
          <span>Stonybrook Strings</span>
          <span>Melrose, MA 02176</span>
        </div>
      </div>

      <div className={styles.right}>
        {/*<a href="https://www.facebook.com/stonybrookstrings/" target="_blank" rel="noopener noreferrer">*/}
        {/*  <FbLogo className={styles.logo} />*/}
        {/*</a>*/}
        <a href="https://www.instagram.com/stonybrookstrings/" target="_blank" rel="noopener noreferrer">
          <InstagramLogo className={styles.logo} />
        </a>
      </div>
    </div>
  </div>
);

export default Footer;
