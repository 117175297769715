import React from 'react';
import Link from 'gatsby-link';
import LoginModal from '../LoginModal';
import * as styles from './styles.module.scss';

class NavLinks extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showLoginModal: !!this.props.loginModalInitiallyOpen  }
  } 

  toggleLoginModal() {
    this.setState((state) => ({ showLoginModal: !state.showLoginModal }))
  }

  render() {
    const classList = [
      this.props.className,
      styles.root,
      this.props.modal ? styles.column : styles.row
    ];

    return (
      <>
        <div className={classList.join(' ')}>
          <Link to="/about/" activeClassName={styles.active} onClick={this.props.onLinkClick}>
            About
          </Link>
          <Link to="/classes/" activeClassName={styles.active} onClick={this.props.onLinkClick}>
            Classes
          </Link>
          <Link to="/testimonials/" activeClassName={styles.active} onClick={this.props.onLinkClick}>
            Testimonials
          </Link>
          <Link to="/faculty/" activeClassName={styles.active} onClick={this.props.onLinkClick}>
            Faculty
          </Link>
          <Link to="/events/" activeClassName={styles.active} onClick={this.props.onLinkClick}>
            Events
          </Link>
          <Link to="/contact/" activeClassName={styles.active} onClick={this.props.onLinkClick}>
            Contact
          </Link>
          <Link to="#" activeClassName={styles.active} onClick={() => this.toggleLoginModal()}>
            Login
          </Link>
        </div>
        {this.state.showLoginModal && <LoginModal onClose={()=> this.toggleLoginModal()} />}
      </>
    );
  }
}

export default NavLinks;
