import React from 'react';

const InstagramLogo = ({ className }) => (
  <svg
    className={className}
    width="35px"
    height="36px"
    viewBox="0 0 35 35"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-727.000000, -1655.000000)" fillRule="nonzero">
        <g transform="translate(0.000000, 1613.000000)">
          <g transform="translate(684.000000, 42.000000)">
            <g transform="translate(43.000000, 0.000000)">
              <path d="M17.3775,20.8572 C19.2948,20.8572 20.8579,19.2969 20.8579,17.3782 C20.8579,16.6208 20.6101,15.9215 20.1999,15.3503 C19.5678,14.4739 18.5402,13.8992 17.3796,13.8992 C16.2183,13.8992 15.1914,14.4732 14.5579,15.3496 C14.1463,15.9208 13.9006,16.6201 13.8999,17.3775 C13.8978,19.2962 15.4595,20.8572 17.3775,20.8572 Z" />
              <polygon points="24.9746 13.1222 24.9746 10.206 24.9746 9.772 24.5385 9.7734 21.6237 9.7825 21.6349 13.1334" />
              <path d="M17.3782,0 C7.7959,0 0,7.7959 0,17.3782 C0,26.9598 7.7959,34.7564 17.3782,34.7564 C26.9598,34.7564 34.7564,26.9598 34.7564,17.3782 C34.7564,7.7959 26.9612,0 17.3782,0 Z M27.2615,15.3503 L27.2615,23.4423 C27.2615,25.55 25.5479,27.2629 23.4416,27.2629 L11.3148,27.2629 C9.2078,27.2629 7.4949,25.55 7.4949,23.4423 L7.4949,15.3503 L7.4949,11.3155 C7.4949,9.2085 9.2078,7.4956 11.3148,7.4956 L23.4409,7.4956 C25.5479,7.4956 27.2615,9.2085 27.2615,11.3155 L27.2615,15.3503 Z" />
              <path d="M22.7843,17.3782 C22.7843,20.3581 20.3595,22.7843 17.3782,22.7843 C14.3969,22.7843 11.9728,20.3581 11.9728,17.3782 C11.9728,16.6614 12.1156,15.9761 12.3704,15.3503 L9.4199,15.3503 L9.4199,23.4423 C9.4199,24.4881 10.269,25.3351 11.3141,25.3351 L23.4402,25.3351 C24.4839,25.3351 25.3344,24.4881 25.3344,23.4423 L25.3344,15.3503 L22.3825,15.3503 C22.6394,15.9761 22.7843,16.6614 22.7843,17.3782 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default InstagramLogo
