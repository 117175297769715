import React from 'react';
import Link from 'gatsby-link';

import NavLinks from 'components/NavLinks';
import Hamburger from 'components/Hamburger';
import Logo from 'components/Logo/Long';
import LogoWispy from 'components/Logo/LongWispy';
import * as styles from './styles.module.scss';

class Header extends React.Component {
  isHomepage() {
    const pathname = this.props.location.pathname.replace(/\/$/, ''); // Replace trailing slash
    return pathname === '';
  }

  render() {
    const classList = [styles.root];
    const { loginModalInitiallyOpen } = this.props

    this.props.className && classList.push(this.props.className);
    this.props.transparent && classList.push(styles.transparent);

    return (
      <div className={classList.join(' ')}>
        <Hamburger
          id={styles.hamburger}
          isActive={this.props.overlayOpen}
          onClick={() => this.props.toggleOverlay()}
        />
        <NavLinks className={styles.links} loginModalInitiallyOpen={loginModalInitiallyOpen}/>
        {!this.isHomepage() && <Link to="/" className={["headerLogo", styles.logo].join(" ")}>
            <LogoWispy className={[styles.svg, styles.wispy].join(" ")} />
            <Logo className={[styles.svg, styles.nonWispy].join(" ")} />
        </Link>}
      </div>
    );
  }
}

export default Header;
