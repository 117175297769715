import React from 'react';
import * as styles from './styles.module.scss';

class Hamburger extends React.Component {
  render() {
    const classList = [styles.hamburgerRoot, styles.hamburgerSpin];

    this.props.isActive && classList.push('is-active');

    return (
      <button
        id={this.props.id}
        className={classList.join(' ')}
        onClick={() => this.props.onClick()}
        type="button"
      >
        <span className={styles.hamburgerBox}>
          <span className={styles.hamburgerInner} />
        </span>
      </button>
    );
  }
}

export default Hamburger;
